import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="select-timezone"
export default class extends Controller {
  static targets = ['select']

  connect() {
    // ブラウザからタイムゾーンを取得
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

    // タイムゾーンを `select` 要素に設定
    if (this.selectTarget) {
      const options = Array.from(this.selectTarget.options)
      const matchingOption = options.find(
        option => option.value === userTimeZone
      )
      if (matchingOption) {
        this.selectTarget.value = userTimeZone
      }
    }
  }
}

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['hourlyRateSection', 'statusRadio']

  connect() {
    this.toggleHourlyRateSection()
  }

  toggleHourlyRateSection() {
    const selected = this.statusRadioTargets.find(radio => radio.checked)?.value

    console.log(selected)

    if (selected === 'active') {
      this.hourlyRateSectionTarget.classList.remove('hidden')
    } else {
      this.hourlyRateSectionTarget.classList.add('hidden')
    }
  }
}

import Splide from '@splidejs/splide'
import '@splidejs/splide/css'
import '@splidejs/splide/dist/css/splide.min.css'

export function splideInstance(target, options) {
  const splide = new Splide(target, {
    ...options,
    classes: {
      // Add classes for arrows.
      arrows: 'splide__arrows',
      arrow: 'splide__arrow',
      prev: 'splide__arrow--prev',
      next: 'splide__arrow--next'
    }
  })

  // BugFix: Browser BackでPaginationが増殖する問題を対応
  splide.on('pagination:mounted', function (data) {
    if (data.list.children.length > data.items.length) {
      for (let i = 0; i < data.items.length; i++) {
        data.list.removeChild(data.list.children[0])
      }
    }
  })

  return splide.mount()
}
